<template>
  <div class="modal-overlay">
    <div class="modal">
      <img class="close-img" src="../assets/close-icon.png" @click="close"/>
      <h6>Gebruikers toevoegen</h6>
      <div v-on:keyup.enter="addUsers()">
        <input class="form-control input" :class="{ 'is-invalid': $v.email.$error}" v-model="email" placeholder="Email" @click="showSucceeded=false, showFailure=false, errorMessage = null" :disabled="loading"/>
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">Email is vereist</span>
        </div>
        <p v-if="userInfo.is_staff===true" class="d-inline"> Dementienetwerk: </p> 
        <select v-if="userInfo.is_staff===true" v-model="netwerk"  size="sm" class="custom-dropdown custom-control"  :class="{ 'is-invalid': $v.netwerk.$error}" >
          <option class="select-form-text" v-for="option in dementienetwerken_list" :value="option" :key="option">{{ option }}</option>
        </select>
        <div v-if="userInfo.is_staff===true && $v.netwerk.$error" class="invalid-feedback">
          <span v-if="userInfo.is_staff===true && !$v.netwerk.required">Dementienetwerkcode is vereist</span>
        </div>
      </div>
      <div>
        <button class="btn btn-secondary" @click="addUsers()" :disabled=" loading || showFailure || showFailureNetwerk">Opslaan</button>
      </div>
      <label v-if="showSucceeded">Gebruiker is toegevoegd</label>
      <label v-if="showFailure && failure_type==='email'" class="failure">ERROR: Er bestaat al een gebruiker met dit emailadres.</label>
      <label v-else-if="showFailure && failure_type==='email_invalid'" class="failure">ERROR: Het emailadres is niet geldig.</label>
      <label v-else-if="showFailure && failure_type==='username'" class="failure">ERROR: Er bestaat al een gebruiker met deze gebruikersnaam.</label>
      <label v-if="showFailureNetwerk && failure_type==='netwerk'" class="failure">ERROR: Er bestaat al een gebruiker voor dit dementienetwerk.</label>
      <font-awesome-icon v-if="loading" class="fa" icon="spinner" spin />
    </div>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddUserModal',
  props: {
    userInfo: {},
    dementienetwerken_list: {},
    dementienetwerken_dict: {},
    dementienetwerken_users: {}
  },
  data () {
    return {
      email: "",
      netwerk: "",
      errorMessage: null,
      errorMessageDementienetwerkcode: null,
      showSucceeded: false,
      showFailure: false,
      showFailureNetwerk: false,
      failure_type: null,
      loading: false
    }
  },
  validations: {
    email: { required },
    netwerk: {required}
  },
  watch: {
    netwerk () {
      this.checkNetwerk();
    },
  },
  computed: {
    defineDementienetwerkcode() {
      if (this.userInfo.is_staff===true) {
        if (this.netwerk!="") {
          return this.dementienetwerken_dict.find(dementienetwerk => dementienetwerk.dementienetwerknaam === this.netwerk).dementienetwerkcode
        } else {
          return 0
        }
      } else {
        return this.userInfo.dementienetwerk
      }
    },
    defineUsername() {
      if (this.userInfo.is_staff===true) {
        return 'Netwerkcoordinator_' + this.netwerk.split(' ').join('_')
      } else {
        return this.email
      }
    },
    defineIsregiocoordinator() {
      if (this.userInfo.is_staff===true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.showSucceeded = false;
      this.showFailure = false;
      this.errorMessage = null;
      this.netwerk = null;
      this.$v.$reset()
    },
    addUsers () {
      if (this.userInfo.is_staff===false) {
        this.netwerk="coordinator"
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if(this.email){
          if(this.defineDementienetwerkcode!==0){
            this.loading = true
            const password = Math.random().toString(36).slice(2)
            const requestBody = {
              email : this.email,
              username : this.defineUsername,
              password1 : password,
              password2 : password,
              dementienetwerk_id : this.defineDementienetwerkcode,
              is_regiocoordinator : this.defineIsregiocoordinator
            }
            this.$http.post(`/rest-auth/registration/`, requestBody).then(
              () => {
                this.email="";
                this.netwerk="";
                this.loading = false;
                this.showSucceeded=true;
                this.errorMessage = null;
                this.$v.$reset();
              },
              (error) => {
                this.loading = false;
                this.showFailure=true;
                if(Object.keys(error.response.data).includes('email')){
                  if(error.response.data.email[0]==='A user is already registered with this e-mail address.'){
                    this.failure_type = "email"
                  } else if(error.response.data.email[0]==='Het emailadres is niet geldig.'){
                    this.failure_type = "email_invalid"
                  }
                }
                else if(Object.keys(error.response.data).includes('username')){
                  this.failure_type = "username"
                }
              }
            )
          }
        }
      }
    },
    checkNetwerk () {
      if (this.dementienetwerken_users.includes(this.netwerk)) {
        this.showFailureNetwerk=true
        this.failure_type = "netwerk"
      } else {
        this.showFailureNetwerk=false        
      }
    }
  }
}
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 320px;
  width: 570px;
  margin-top: 10%;
  margin-left: 35%;
  padding: 10px 60px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.close-img {
  margin: 0 0 0 470px;
  width: 25px;  
  cursor: pointer;
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 15px;
  width: 130px;
  max-width: 300px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 30px;
  padding-top: 20px;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.fa {
  margin-top: 10px;
  align-self: center;
}

.custom-control {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    font-weight: bold;
    font-size: 18px;
    font-family: inherit !important;
    
}

.custom-control:focus {
    outline: none !important;

}

.custom-dropdown {
  background-color: transparent;
  color: var(--color-1);
  font-weight: bold;
  width: auto;
}


.select-form-text {
  font-weight: bold;
  color: var(--color-2) !important;
  background: var(--color-1) !important;
}

.failure{
  color: red;
}
</style>