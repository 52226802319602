<template>
  <div class="modal-overlay">
    <div class="modal">
      <img class="close-img" src="../assets/close-icon.png" @click="close" />
      <p v-if="!showMessage" class="data-removal-warning">Als u deze gebruiker verwijdert, worden ook de bijbehorende gegevens verwijderd.</p>
      <p v-if="!showMessage">Weet je zeker dat je deze gebruiker(s) wilt verwijderen?</p>
      <div>
        <button v-if="!showMessage" class="btn btn-secondary" @click="deleteUsers()">Ja</button>
        <button v-if="!showMessage" class="btn btn-secondary" @click="close">Nee</button>
        <p v-if="showMessage">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'DeleteUserModal',
  props: {
    selected: {}
  },
  data () {
    return {
      message: "",
      showMessage: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.showMessage=false;
    },
    deleteUsers() {
      const requestBody = {
          users : this.selected,
        }
      this.$http.post(`${process.env.VUE_APP_API_URL}/api/users/delete_user/`, requestBody).then(
        (response) => {
          this.message = response.data.message
          this.showMessage=true
        }
      )
    }
  }
}
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 320px;
  width: 500px;
  margin-top: 10%;
  margin-left: 35%;
  padding: 10px 60px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.close-img {
  margin: 0 0 0 400px;
  width: 25px;  
  cursor: pointer;
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 15px;
  margin-right: 20px;
  width: 130px;
  max-width: 300px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 30px;
  padding-top: 20px;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.fa {
  margin-top: 10px;
  align-self: center;
}

.data-removal-warning{
  font-weight: bold;
  color: var(--color-16);
}
</style>