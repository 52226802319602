<template>
  <div class="about" id="dementie-information">
    <div class="parent clearfix">
    <div class="login">
      <div class="container">
        <p v-if="Object.keys(this.userInfo).length==0" v-bind:style="{ color: 'red' }"> U hebt geen toegang tot deze pagina. </p>
        <div v-if="Object.keys(this.userInfo).length!=0">
          <body>
            <div class="about">
              <h1>Gebruikersbeheer</h1>
              <div v-if="userInfo.is_staff!==true">
                <p class="info">Op deze pagina kun je als netwerkcoördinator gebruikers toevoegen die de netwerkanalyse kunnen invullen of gebruikers verwijderen.</p>
                <p class="info">Je voegt een gebruiker toe door op de knop 'Voeg gebruiker toe' te klikken en het e-mailadres van de nieuwe gebruiker in te voeren. Klik vervolgens op ‘Opslaan’. Als je nog een gebruiker wilt toevoegen, kun je direct na het opslaan een ander e-mailadres invullen. De nieuwe gebruiker krijgt vervolgens een mail met instructies om een wachtwoord aan te maken, zodat hij vervolgens kan inloggen. </p>
                <p class="info">Je verwijdert een gebruiker door de gebruiker(s) te selecteren en dan op de knop 'Verwijder gebruiker(s)' te klikken. Als een gebruiker de inloggegevens kwijt is kun je door de gebruiker te verwijderen en opnieuw aan te maken een nieuwe inlog versturen. </p>
                <p class="info"> Mocht je meer willen weten, download dan de handleiding<a class="link" href="/files/Handleiding Netwerkanalyse voor netwerkcoördinatoren def.pdf" target="_blank">hier</a>. </p>
                <h5>Gebruikers in jouw netwerk:</h5>
              </div>
              <div v-if="userInfo.is_staff===true">
                <p class="info">Op deze pagina kun je als administrator netwerkcoördinatoren toevoegen die de quickscan kunnen invullen, hun emailadres wijzigen of netwerkcoördinatoren verwijderen.</p>
                <p class="info">Je voegt een gebruiker toe door op de knop 'Voeg gebruiker toe' te klikken en het e-mailadres en het dementienetwerk van de nieuwe gebruiker in te voeren. Klik vervolgens op ‘Opslaan’. Als je nog een gebruiker wilt toevoegen, kun je direct na het opslaan een ander e-mailadres en dementienetwerk invullen. De nieuwe gebruiker krijgt vervolgens een mail met instructies om een wachtwoord aan te maken, zodat hij vervolgens kan inloggen. </p>
                <p class="info">Je wijzigt het emailadres van een gebruiker door de gebruiker te selecteren en dan op de knop 'Wijzig gebruiker' te klikken. Vul vervolgens het nieuwe emailadres van de gebruiker in en druk op 'Wijzig'. Het is niet mogelijk om de emailadressen van meerdere gebruikers tegelijkertijd te wijzigen. </p>
                <p class="info">Je verwijdert een gebruiker door de gebruiker(s) te selecteren en dan op de knop 'Verwijder gebruiker(s)' te klikken. Als een gebruiker de inloggegevens kwijt is kun je door de gebruiker te verwijderen en opnieuw aan te maken een nieuwe inlog versturen. </p>
                <p class="info"> Mocht je meer willen weten, download dan de handleiding<a class="link" href="/files/Handleiding Netwerkanalyse voor administrators def.pdf" target="_blank">hier</a>. </p>
                <h5>Gebruikers in jouw netwerk:</h5>
              </div>
              <div id="app">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>
                        <label class="form-checkbox">
                          <input type="checkbox" v-model="selectAll" @click="select">
                          <i class="form-icon"></i>
                        </label>
                      </th>
                      <th>Username</th>
                      <th v-if="userInfo.is_staff===true">Email</th>
                      <th v-if="userInfo.is_staff===true">Dementienetwerk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="i in options" :key="i.id">
                      <td>
                        <label class="form-checkbox">
                            <input type="checkbox" :value="i" v-model="selected">
                          <i class="form-icon"></i>
                          </label>
                      </td>
                      <td>{{i.username}}</td>
                      <td v-if="userInfo.is_staff===true">{{i.email}}</td>
                      <td v-if="userInfo.is_staff===true">{{i.dementienetwerknaam}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="btn btn-secondary" @click="showAddUserModal" block>Voeg gebruiker toe</button>
              <add-user-modal :userInfo="userInfo" :dementienetwerken_list="dementienetwerken_list" :dementienetwerken_dict="dementienetwerken_dict" :dementienetwerken_users="dementienetwerken_users" v-show="isAddUserModalVisible" @close="closeModal"/>
              <button v-if="userInfo.is_staff===true" class="btn btn-secondary" @click="showEditUserModal" :disabled="!isAddUserModalVisible && !isEditUserModalVisible && !isDeleteUserModalVisible && selected.length !== 1">Wijzig gebruiker</button>
              <edit-user-modal v-if="userInfo.is_staff===true" :selected="selected" v-show="isEditUserModalVisible" @close="closeModal"/>
              <button class="btn btn-secondary" @click="showDeleteUserModal" :disabled="!isAddUserModalVisible && !isDeleteUserModalVisible && selected.length === 0">Verwijder gebruiker(s)</button>
              <delete-user-modal :selected="selected" v-show="isDeleteUserModalVisible" @close="closeModal"/>
              <button class="btn btn-secondary" @click="backToMenu" >Terug naar menu</button>
              <p>{{ message }}</p>
            </div>
          </body>
        </div>
      </div>
    </div>

    <div class="bg-illustration">
      <img src="../assets/womanhealth_icon.png" alt="logo">
    </div>
  </div>
  </div>
</template>


<script>
import AddUserModal from '../components/AddUserModal.vue'
import EditUserModal from '../components/EditUserModal.vue'
import DeleteUserModal from '../components/DeleteUserModal.vue'
import icon from '@/assets/womanhealth_icon.png'
import { mapGetters } from 'vuex'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Gebruikersbeheer',
  },
  components: { AddUserModal, EditUserModal, DeleteUserModal},
  name: 'Gebruikersbeheer',
  data () {
    return {
      icon:icon,
      options: [],
      isAddUserModalVisible: false,
      isEditUserModalVisible: false,
      isDeleteUserModalVisible: false,
      message: "",  
      selected: [],
      selectAll: false,
      dementienetwerken_list: [],
      dementienetwerken_dict: [],
      dementienetwerken_users: []
    }
  },
  watch: {
    isAddUserModalVisible () {
      this.loadAllData();
      this.loadDementienetwerken();
    },
    isEditUserModalVisible () {
      this.loadAllData();
      this.loadDementienetwerken();
    },
    isDeleteUserModalVisible () {
      this.loadAllData();
      this.loadDementienetwerken();      
    },
    message () {
      this.loadAllData();
      this.loadDementienetwerken();
    }
  },
  computed: {
    ...mapGetters({userInfo: 'userInfo' })
  },
  methods: {
    loadAllData () {
      this.$http.get(`/api/users/`).then(
        (response) => {
          this.options = response.data
          this.dementienetwerken_users = response.data.map(d => (d.dementienetwerknaam))
        }
      )
    },
    loadDementienetwerken () {
      this.$http.get(`/api/dementienetwerken/`).then(
        (response) => {
          this.dementienetwerken_dict = response.data
          this.dementienetwerken_list = response.data.map(d => (d.dementienetwerknaam))
        }
      )
    },
    showAddUserModal() {
      this.isAddUserModalVisible = true;
    },
    closeModal() {
      this.isAddUserModalVisible = false;
      this.isEditUserModalVisible = false;
      this.isDeleteUserModalVisible = false;
      this.selected = [];
    },
    showEditUserModal() {
      this.isEditUserModalVisible = true;
    },
    showDeleteUserModal() {
      this.isDeleteUserModalVisible = true;
    },
    backToMenu() {
      this.$router.push('/zorgstandaard/Netwerkcoordinator/')
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.options) {
          this.selected.push(this.options[i]);
        }
      }
    }
  },
  mounted () {
    this.loadAllData()
    this.loadDementienetwerken()
  }
}
</script>

<style scoped>

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 30px;
  margin-right: 50px;
  width: 200px;
  max-width: 300px
}

.login {
  max-height: 100vh;
/*  overflow-Y: auto;*/
  float: left;
  margin: 0 auto;
  width: calc(100% - 1000px);
}

.login .container {
  width: 1000px;
  margin: 0 auto;
}

.login .container h1 {
  margin-top: 100px;
  font-size: 35px;
  font-weight: bolder;
}

.login .container .login-form {
  margin-top: 80px;
}

.login .container .login-form form {
  display: -ms-grid;
  display: grid;
}

.info{
  margin: 1rem 0 1rem 0;
}

.mt-3 {
  font-style: normal;
}

button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.bg-illustration {
/*  position: relative;*/
  width: 1000px;
  background: transparent no-repeat center center scroll;
  background-size: cover;
  float: left;
}
.bg-illustration img {
  width: 165px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: auto;
  margin: 100px 0 0 500px;
}

.link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-5);
}

.link:visited {
  color: var(--color-7);
}

.link:hover {
  border-bottom: 1px solid;
  color: var(--color-2);
  background: var(--color-1);
  border-radius: .5em;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  .bg-illustration {
    width: 50%;
    -webkit-animation: none;
            animation: none;
  }

  .login {
    width: 50%;
  }
}
/* Display 12", iPad PRO Portrait, iPad landscape */
@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  .bg-illustration {
    float: none;
    background: transparent center center;
    background-size: cover;
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 100%;
    height: 190px;
    text-align: center;
  }
  .bg-illustration img {
    width: 100px;
    height: auto;
    margin: 20px auto !important;
    text-align: center;
  }
  .bg-illustration .burger-btn {
    left: 33px;
    top: 29px;
    display: block;
    position: absolute;
  }
  .bg-illustration .burger-btn span {
    display: block;
    height: 4px;
    margin: 6px;
    background-color: #fff;
  }
  .bg-illustration .burger-btn span:nth-child(1) {
    width: 37px;
  }
  .bg-illustration .burger-btn span:nth-child(2) {
    width: 28px;
  }
  .bg-illustration .burger-btn span:nth-child(3) {
    width: 20px;
  }

  .login {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .login .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
  .login .container h1 {
    font-size: 25px;
    margin-top: 40px;
  }
  .login .container .login-form {
    margin-top: 90px;
  }
  .input {
    height: 45px;
  }
  .button[type=submit] {
    height: 45px;
    margin-top: 100px;
  }
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1024px) and (max-width: 1680px) {
  .bg-illustration {
    width: 50%;
    -webkit-animation: none;
            animation: none;
  }

  .login {
    width: 50%;
  }
}
/* Display 12", iPad PRO Portrait, iPad landscape */
@media only screen and (max-width: 1024px) {
  body {
    overflow-x: hidden;
  }

  @-webkit-keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }
  .bg-illustration {
    float: none;
    background: transparent center center;
    background-size: cover;
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 100%;
    height: 190px;
    text-align: center;
  }
  .bg-illustration img {
    width: 100px;
    height: auto;
    margin: 20px auto !important;
    text-align: center;
  }
  .bg-illustration .burger-btn {
    left: 33px;
    top: 29px;
    display: block;
    position: absolute;
  }
  .bg-illustration .burger-btn span {
    display: block;
    height: 4px;
    margin: 6px;
    background-color: #fff;
  }
  .bg-illustration .burger-btn span:nth-child(1) {
    width: 37px;
  }
  .bg-illustration .burger-btn span:nth-child(2) {
    width: 28px;
  }
  .bg-illustration .burger-btn span:nth-child(3) {
    width: 20px;
  }

  .login {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .login .container {
    -webkit-animation: slideIn 0.8s ease-in-out forwards;
            animation: slideIn 0.8s ease-in-out forwards;
    width: 85%;
    float: none;
  }
  .login .container h1 {
    font-size: 25px;
    margin-top: 40px;
  }
  .login .container .login-form {
    margin-top: 90px;
  }
  .input {
    height: 45px;
  }
  .button[type=submit] {
    height: 45px;
    margin-top: 100px;
  }
}
</style>
